<template>
    <div>
        <div class="title">考勤机信息</div>

        <el-divider></el-divider>

        <el-form
            ref="form"
            :rules="rules"
            label-position="top"
            :model="form"
            label-width="80px"
        >
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="考勤机型号" prop="sn_code">
                <el-input v-model="form.sn_code"></el-input>
            </el-form-item>

            <el-form-item label="设备序列号" prop="serial_number">
                <el-input v-model="form.serial_number"></el-input>
            </el-form-item>

            <el-form-item label="反向代理域名" prop="proxy_url">
                <el-input v-model="form.proxy_url"></el-input>
            </el-form-item>

            <el-form-item class="form_btns">
                <div class="form_btns">
                    <div
                        class="custom_button plain no_select"
                        @click="onSubmit"
                    >
                        保存
                    </div>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            form: {
                name: "",
                sn_code: "",
                serial_number: "",
                proxy_url: "",
            },
            rules: {
                name: [
                    { required: true, message: "请填写名称", trigger: "blur" },
                ],
                sn_code: [
                    {
                        required: true,
                        message: "请填写考勤机型号",
                        trigger: "blur",
                    },
                ],
                serial_number: [
                    {
                        required: true,
                        message: "请填写设备序列号",
                        trigger: "blur",
                    },
                ],
                proxy_url: [
                    {
                        required: true,
                        message: "请填写反向代理域名",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: ["id"],
    created() {
        if (this.id) this.form.id = this.id;
    },
    methods: {
        ...mapActions("attendance", ["postMachinePunchWay"]),

        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    console.log(this.form);
                    this.postMachinePunchWay(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success("信息提交成功");
                            this.$router.back();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error("信息提交失败");
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },

        onReset() {
            this.$confirm("重置表单, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$refs.form.resetFields();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
    position: relative;

    .title_button {
        width: 124px;
        position: absolute;
        right: 0;
        top: -10px;
    }
}

::v-deep .el-form-item__label {
    font-size: 14px;
    color: #222222;
}

.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}

.form_btns {
    display: flex;
    align-items: center;

    .custom_button {
        width: 142px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
    }

    > div:not(:first-child) {
        margin-left: 20px;
    }
}
</style>
